import "./requestscr.scss"
import {useState} from "react";
import {validate} from "uuid";
import Popup from "../popup/popup";
const Requestscr = () => {

    const initialFormData = {
        name: '',
        email: '',
        service: '',
        name_project: '',
        project_descr: '',
    }
    const [formData, setFormData] = useState(initialFormData);

    const data = [
        {id: "name", label: "имя", type: "text"},
        {id: "email", label: "почта", type: "email"},
        {id: "service", label: "интересующая услуга", type: "text"},
        {id: "promo", label: "промокод", type: "text"},
        {id: "name_project", label: "название проекта", type: "text",},
        {id: "project_descr", label: "описание проекта", type: "textarea"},
    ];

    const [statusForm, setStatusForm] = useState({
        loaded: false,
        status: false,
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const onSubmitHandler = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch("https://scixplore.ru:3005//requests", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                // обработка успешного ответа
                setFormData(initialFormData);
                setStatusForm({
                    loaded: true,
                    status: true,
                });
                console.log('Успешно отправлено');
            } else {
                // обработка ошибки
                setStatusForm({
                    loaded: true,
                    status: false,
                });
                console.error('Ошибка при отправке');
            }

        } catch (error) {
            console.error('Ошибка:', error);
        }
    }

    const inputsRender = () => {
        return data.map(({id, type, label}) => {
            return (
                <div className="request__input">
                    <label htmlFor={id}>{label}</label>
                    <input name={id}
                           id={id}
                           type={type}
                           value={formData[id]}
                           onChange={handleChange}
                           required
                    />
                </div>
            )
        })
    }



    const popup = statusForm.loaded
        ? <Popup message={statusForm.status ? "Данные отправлены" : "Ошибка отправки"}
               type={statusForm.status ? "ok" : "bad"}/>
        : null;
    // const popup = <Popup message={"Данные отправлены"}/>;

    return (
        <div className={"request"}>
            {popup}
            <div className={"container"}>
                <div className="request__wrapper">
                    <div className="request__wrapper-inner">
                        <h2 className={"request__title"}>Оставьте заявку</h2>

                        <form action="#" className="request__form" onSubmit={onSubmitHandler}>
                            {inputsRender()}

                            <div className="request__triggers">
                                <button className="request__btn" >Отправить</button>
                                <div className="request__policy">
                                    <input required type="checkbox"/>
                                    <span>Я согласен(а) с  <a href="/policy">политикой конфиденциальности</a></span>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Requestscr;