import {useState} from "react";
import "./popup.scss";

const Popup = ({message, type}) => {
    const [open, setOpen] = useState(true);

    console.log("message = ", message)
    setTimeout(() => setOpen(false), 6000);

    let classes = "popup"
    classes += open ?  " popup-active" : "";
    classes += type === "ok" ? " popup_ok" : "";
    classes += type === "bad" ? " popup_bad" : "";

    return (
        <div className={classes}>
            <div className="popup__text">
                {message}
            </div>
        </div>
    )

}
export default Popup;