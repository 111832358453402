import "./team.scss"
import {useEffect, useRef} from "react";
import { register } from 'swiper/element/bundle';
import Swiper from "swiper";

import 'swiper/swiper-bundle.css';

const Team = () => {

    const teamData = [
        {firstname: "Вадлен", secondname: "Тюлюбаев", position: "Операционка и финансы", img: "/images/team/vady.jpg"},
        {firstname: "Анна", secondname: "Шевейко", position: "Стратегия и маркетинг", img: "/images/team/ann.jpg"},
        {firstname: "Анна", secondname: "Крючечникова", position: "Контент и SMM", img: "/images/team/anya.jpg"},
        {firstname: "Михаил", secondname: "Бородин", position: "Техническое обеспечение", img: "/images/team/rbm.jpg"},
        {firstname: "Анастасия", secondname: "Карпенко", position: "SMM-специалист", img: "/images/team/nasty.jpg"},
        {firstname: "Лариса", secondname: "Цыгунова", position: "Бизнес-ассистент", img: "/images/team/larisa.jpg"},
        {firstname: "Ольга", secondname: "Салып", position: "Проектный менеджер sciXbee", img: "/images/team/helga.jpg"},
        {firstname: "Камиль", secondname: "Зайнуллин", position: "Менеджер по работе с ключевыми партнерами", img: "/images/team/kamil.jpg"},
    ];

    const renderCards = () => {
        return teamData.map(({firstname, secondname, position, img}, index) => {
            return (
                <swiper-slide key={index}>
                    <Card
                        firstname={firstname}
                        lastname={secondname}
                        position={position}
                        img={img}
                    />
                </swiper-slide>

            )
        })
    }


    return (
        <div className={"team"}>
            <h2 className={"team__title"}>Команда</h2>
            <Slider data={renderCards()}/>
            <div className={"team__progress-bar"}>
                <div className={"team__progress-bar_inner"}></div>
            </div>
        </div>
    )
}

register();
const Slider = ({data}) => {

    const swiperElRef = useRef(null);

    useEffect(() => {
        const mySwiper = new Swiper(swiperElRef.current, {
            slidesPerView: 'auto',
            navigation: true,
            pagination: true,
            paginationClickable: true,
            freeMode: true, // Включаем свободный режим прокрутки
            // touchRatio: 2, // Устанавливаем коэффициент прокрутки двумя пальцами
            spaceBetween: 40, // Устанавливаем расстояние между слайдами
            direction: 'horizontal',
            grabCursor: true,

            scrollbar: {
                el: ".team__progress-bar_inner",
                dragClass: 'team__progress-bar_drag',
                // hide: true,
            },
        });

        const handleWheelHorizontal = (event) => {
            if (event.shiftKey) {
                event.preventDefault();
                if (event.deltaY > 0) {
                    mySwiper.slideNext();
                } else {
                    mySwiper.slidePrev();
                }
            }
        };

        // прокрутка горизонтальная мышкой
        // const swiperEl = swiperElRef.current;
        const swiperEl = swiperElRef.current;
        swiperEl.addEventListener('wheel', handleWheelHorizontal);

        // Очищаем слушатели событий при размонтировании компонента
        return () => {
            swiperEl.removeEventListener('wheel', handleWheelHorizontal);
            mySwiper.destroy();
        };
    }, []); // Пустой массив зависимостей для вызова эффекта только один раз при монтировании

    useEffect(() => {
        // listen for Swiper events using addEventListener
        swiperElRef.current.addEventListener('swiperprogress', (e) => {
            const [swiper, progress] = e.detail;
            console.log(progress);


        });

        swiperElRef.current.addEventListener('swiperslidechange', (e) => {
            console.log('slide changed');
        });
    }, []);

    return (
        <>
            <div className="swiper-container" ref={swiperElRef}>
                <div className="swiper-wrapper">
                    {data}
                </div>
            </div>


        </>
    );
}

const Card = ({firstname, lastname, position, img}) => {
    return (
        <div className={"card-wrapper"}>
            <img className={"card__img"} src={img} alt=""/>
            <div className="card__text">
                <div className="card__text__name">{firstname}<br/>{lastname}</div>
                <div className="card__text__position">{position}</div>
            </div>
        </div>
    )
}

export default Team;