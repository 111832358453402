import "./social.scss"

const Social = () => {
    return (
        <div className={"social-wrapper"}>
            <div className={"social"}>
                <a href="https://www.instagram.com/scixplore" className={"social__link"}>
                    <img src="/icons/social/instagram.svg" className={"social__icon"}
                         alt="instagram"/>
                </a>
                <a href="https://t.me/sciXplore" className={"social__link"}>
                    <img src="/icons/social/telegram.svg" className={"social__icon"}
                         alt="instagram"/>
                </a>
                <a href="https://vk.com/scixplore" className={"social__link"}>
                    <img src="/icons/social/vk.svg" className={"social__icon"} alt="instagram"/>
                </a>
            </div>
            <a className={"email"} href="mailto:example@example.com">scixplore@internet.ru</a>
        </div>
    )
}

export default Social;