import MainPage from "../mainPage/MainPage";
import "../../styles/index.scss"
import "./app.scss"
import AppHeader from "../appHeader/AppHeader";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Policy from "../policy/policy";
import BlogPage from "../blogPage/BlogPage";
import PortfolioPage from "../portfolioPage/PortfolioPage";
import WorkersPage from "../workersPage/WorkersPage";
import BusinessPage from "../businessPage/BusinessPage";
import Footer from "../footer/Footer";
const App = () => {
    return (
        <Router>
            <main className={"app"}>
                <div className={"content"}>
                    <AppHeader/>
                    <Routes>
                        <Route path={"/"} element={<MainPage/>}/>
                        <Route path={"/policy"} element={<Policy/>}/>
                        <Route path={"/blog"} element={<BlogPage/>}/>
                        <Route path={"/portfolio"} element={<PortfolioPage/>}/>
                        <Route path={"/workers"} element={<WorkersPage/>}/>
                        <Route path={"/business"} element={<BusinessPage/>}/>
                    </Routes>
                    <Footer/>
                </div>
            </main>
        </Router>
    )
}

export default App;