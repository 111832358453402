import "./Logo.scss"
const Logo = () => {
    return (
        <div className={"logo"}>
            <div className={"logo__main"}>sci<span>x</span>plore</div>
            <div className={"logo__slogan"}>вдыхая жизнь</div>
        </div>

    )
}

export default Logo;