import "./policy.scss"

const Policy = () => {
    return (
        <div className="policy">
            <embed src="/docs/policy.pdf" type="application/pdf" height={"100%"} width={"100%"}/>
        </div>
    )

}

export default Policy