const BlogPage = () => {
    return (
        <div style={{
            marginTop: 200,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <div style={{color: 'white', padding: '10px', paddingBottom: '10px', fontWeight: 'bold', fontSize: '36px'}}>
                Раздел в разработке
            </div>
        </div>
    )
}

export default BlogPage