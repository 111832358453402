import "./footer.scss";
import Social from "../social/Social";

const Footer = () => {

    return (
        <footer>
            <div className="container">
                <div className="footer">
                    <div className={"footer__title"}>Контактная информация</div>
                    <div className="footer__wrapper">
                        <div className="footer__item footer__left-side">
                            {/*<div className={"footer__left-side"}>*/}
                                2024
                            {/*</div>*/}
                        </div>

                        <div className="footer__item">
                            <Social/>
                        </div>

                        <div className="footer__item">
                            <div className={"footer__right-side"}>
                                <a href="/policy">Политика конфиденциальности</a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </footer>
    )
}

export default Footer;