import "./servicesscr.scss"
const Servicesscr = () => {
    const data = [
        "Анализ рынка и конкурентов",
        "Разработка стратегии развития бизнеса",
        "Оценка эффективности бизнес-процессов",
        "Поиск новых возможностей для роста бизнеса",
        "Разработка маркетинговой стратегии",
        "Обучение персонала",
        "Помощь в поиске и подборе персонала",
        "Консультации по юридическим вопросам",
        "Консультации по финансовым вопросам",
    ]

    const renderLi = () => {
        return (
            data.map((item) => {
                return (<li>{item}</li>)
            })

        )
    }

    return (
        <div className={"services"}>
            <div className="container">
                <div className="services__wrapper">
                    <div className="services__wrapper-inner">
                        <h2 className={"services__title"}>
                            Спектр оказываемых услуг
                        </h2>
                        <div className={"services__list"}>
                            <ul>{renderLi()}</ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Servicesscr;