import Preview from "../preview/preview";
import "./mainpage.scss"
import Servicesscr from "../sevicesscr/Servicesscr";
import Team from "../team/Team";
import Requestscr from "../requestscr/Requestscr";

const MainPage = () => {
    return (
        <div className={"mainpage"}>
                <Preview/>
                <Servicesscr/>
                <Team/>
                <Requestscr/>
        </div>
    )
}

export default MainPage;