import "./appheader.scss"
import {NavLink} from "react-bootstrap";
import Logo from "../logo/Logo";
import {useState} from "react";
import Hamburger from "../hamburger/hamburger";
import Social from "../social/Social";


const AppHeader = () => {
    // const activeLink = ({isActive}) =>  ({
    //     "color": isActive ? "#5870F6" : "inherit",
    // })

    const [isActiveHamburger, setIsActiveHamburger] = useState(false);

    const data = [
        {
            label: "business",
            id: 1
        },
        {
            label: "workers",
            id: 2
        },
        {
            label: "blog",
            id: 3
        },
        {
            label: "portfolio",
            id: 4
        },
    ]

    const renderLinks = () => {
        const links =  data.map(item => {
            return (
                <li key={item.id}>
                    <NavLink
                        className={"header__link"}
                        activeClassName={"header__link-active"}
                        to={`/${item.label}`}
                        href={`/${item.label}`}
                    >
                        <span>sciX</span>{item.label}
                    </NavLink>
                </li>
            )
        });

        return (
            // <ul className={isActiveHamburger ? "header__links header__links-active" : "header__links"}
            <ul className={"header__links"}
                // style={{backdropFilter: "blur(50px)"}}
            >
                {links}
            </ul>
        )
    };


    const handleClickHamburger = () => {
        setIsActiveHamburger(!isActiveHamburger);
    };




    return (
        <header>
            <a href={"/"}>
                <Logo/>
            </a>

            <div className={isActiveHamburger ? "header__links__wrapper header__links-active" : "header__links__wrapper "}>
                <div className="header__links__wrapper-inner">
                    {renderLinks()}

                    <Social/>
                </div>
            </div>

            <Hamburger onClick={handleClickHamburger}
                       activeClass={isActiveHamburger ? "active" : ""}
            />
        </header>
    )
}

export default AppHeader