import "./preview.scss"

const Preview = () => {
    return (
        <div className={"preview"}>
            <div className="container">
                <div className={"preview__text"}>
                    <h1>Прокачай <br/>свою карьеру</h1>
                    <div className={"preview__text__descr"}>
                        Развивайте проекты и получайте уникальные навыки в биотехе, медицине и фармацевтике с нашим
                        консалтинговым центром и посреднической платформой
                    </div>
                </div>
            </div>


        </div>
    )
}

export default Preview;